import React, { useEffect, useState } from "react";
import {
	Button,
	Checkbox,
	Form,
	Layout,
	Input,
	Select,
} from "antd";
import InputMask from 'react-input-mask';
import { Header, Content, Footer } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { SimpleFooter } from "../../components/SimpleFooter";
import { useParams } from "react-router-dom";
import {
	clearNumber,
	getFormValues,
	submitFormValues,
} from "../../../store/helpers/formHelper";
import { showError } from "../../../store/helpers/generalHelper";
import { useRef } from "react";

const MemberFormPage = () => {
	const [phone1, setPhone1] = useState("");
	const [phone2, setPhone2] = useState("");
	const [error, setError] = useState(false);
	const [memberData, setMemberData] = useState(null);
	const [listDependiente, setListaDependiente] = useState([]);
	const [form] = Form.useForm();
	const formRef = useRef();
	const { id } = useParams();
	const navigate = useNavigate();

	const config = {
		rules: [{ required: true, message: "ingrese un valor válido" }],
	};
	useEffect(() => {
		const getMemberData = async (id) => {
			const data = await getFormValues(id);
			console.log('cargamos la informacion de:')
			console.log(data);
			if (data != null) {
				setMemberData(data);
			
				let newValues = {
					name1: data.primerNombre,
					name2: data.segundoNombre,
					lastname1: data.primerApellido,
					lastname2: data.segundoApellido,
					marriedname: data.apellidoCasada,
					correo: data.email,
					phone1: data.telefono,
					phone2: data.telefono2,
					interest: [],
					dependent: data.origin_fam + " - " + data.name + " " + data.lastname
				}
				setListaDependiente(data.dependientes);
				formRef.current.setFieldsValue(newValues);
			}
		};
		getMemberData(id);
	}, [id]);

	

	let initialValues = {
		name1: memberData ? memberData.primerNombre : "",
		name2: memberData ? memberData.segundoNombre : "",
		lastname1: memberData ? memberData.primerApellido : "",
		lastname2: memberData ? memberData.segundoApellido : "",
		marriedname: memberData ? memberData.apellidoCasada : "",
		correo: memberData ? memberData.email : "",
		phone1: memberData ? memberData.telefono : "",
		phone2: memberData ? memberData.telefono2 : "",
		interest: memberData ? memberData.intereses : [],
		dependent:  memberData ? memberData.origin_fam + " - " + memberData.name + " " + memberData.lastname: null
	};

	const validateInterestSelection = (rule, value) => {
		if (value && value.length >= 2) {
			return Promise.resolve();
		}
		return Promise.reject("Debe seleccionar al menos 2 intereses");
	};

	function cc_format(value) {
		const v = clearNumber(value.toString());
		let formatted = "";
		for (let i = 0; i < v.length; i++) {
			if (i % 4 === 0 && i > 0) {
				formatted += "-"; // add space every 4 characters
			}
			formatted += v[i];
		}
		return formatted;
	}

	const handlePhone1Change = (e) => {
		setPhone1(clearNumber(e.target.value));
	};
	const handlePhone2Change = (e) => {
		setPhone2(clearNumber(e.target.value));
	};

	const handleCheckboxChange = (e) => {
		formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue,
			check: e.target.checked,
		});
	};

	const memberForm = async (e) => {
		console.log(e);
		if (e.check === true) {
			const formValues = {
				origin_fam: id,
				primerNombre: e.name1,
				segundoNombre: e.name2 ? e.name2 : "",
				primerApellido: e.lastname1,
				segundoApellido: e.lastname2 ? e.lastname2 : "",
				apellidoCasada: e.marriedname ? e.marriedname : "",
				correo: e.correo,
				telefono: e.phone1,
				telefono2: e.phone2 ? e.phone2 : "",
				intereses: e.interest ? e.interest : [],
				dependent: e.dependent?e.dependent: ""
			};
			const isRegistered = await submitFormValues(formValues);
			if (isRegistered) {
				navigate(`/descargar`, { replace: false });
			}
		} else {
			setError(true);
		}
	};

	const loadDep = async (e) => {
		console.log(e);
		console.log(listDependiente);
		//buscamos el seleccionado
		let seldep = listDependiente.find(d => d.value === e);
		if(seldep) {
			console.log(seldep.extra);
			let newinfo = seldep.extra;
			formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue,
				name1: newinfo.primerNombre,
				name2: newinfo.segundoNombre,
				lastname1: newinfo.primerApellido,
				lastname2: newinfo.segundoApellido,
				marriedname: newinfo.apellidoCasada,
				correo: newinfo.email,
				phone1: newinfo.telefono,
				phone2: newinfo.telefono2,
				interest: []
			});
			
		}
	};

	const loadMiem = async () => {
		formRef.current.setFieldsValue({ ...formRef.current.getFieldsValue,
			name1: memberData.primerNombre,
			name2: memberData.segundoNombre,
			lastname1: memberData.primerApellido,
			lastname2: memberData.segundoApellido,
			marriedname: memberData.apellidoCasada,
			correo: memberData.correo,
			phone1: memberData.telefono,
			phone2: memberData.telefono2,
			interest: memberData.intereses
		});
	}

	return (
		<>
			<div className="page-wrapper">
				<div className="flex-container">
					<div className="image-section"></div>
					<div className="content-section">
						<Layout className="layout">
							<Header className="header"></Header>
							<Content className="content">
								<div className="content-wrapper">
									<p className="input-text">Bienvenido:</p>
									<h1 className="green-title-text">
										FORMULARIO DE ACTUALIZACIÓN DE DATOS
									</h1>
									<p className="description-text">
										Completa el siguiente formulario de pre-registro para
										obtener tu usuario Topia y disfrutar así de todos los
										beneficios de nuestro servicio:
									</p>

									<Form
										ref={formRef}
										name="member_form"
										onFinish={memberForm} 
										initialValues={initialValues}
									>
										<div className="member-form-container">
										<p className="input-text">Código de miembro: {id} </p>
										<p className="input-text">Dependientes: ({listDependiente.length}) </p>
										<Form.Item
											name="dependent"
										>
											<Select
												className="input select_style"
												placeholder="Seleccionar dependientes"
												allowClear
												onClear={loadMiem}
												onChange={loadDep}
												options={listDependiente}
											/>
										</Form.Item>

										<p className="input-text">Nombre:</p>
										<Form.Item name="name1" {...config}>
											<Input className="input" />
										</Form.Item>

										<p className="input-text">segundo nombre:</p>
										<Form.Item name="name2">
											<Input className="input" />
										</Form.Item>

										<p className="input-text">Apellido:</p>
										<Form.Item name="lastname1" {...config}>
											<Input className="input" />
										</Form.Item>

										<p className="input-text">segundo Apellido:</p>
										<Form.Item name="lastname2">
											<Input className="input" />
										</Form.Item>

										<p className="input-text">Apellido de casad@:</p>
										<Form.Item name="marriedname">
											<Input className="input" />
										</Form.Item>

										<p className="input-text">Correo electrónico:</p>
										<Form.Item name="correo" rules={[{ type: "email", required: true, message: "ingrese un correo valido", }]} >
											<Input className="input" placeholder="user@email.com" />
										</Form.Item>

										<p className="input-text">Celular:</p>
										<Form.Item
											name="phone1"
											rules={[
												{
													required: true,
													message: "Por favor llenar el campo",
												}
											]}
										>
											<InputMask
												mask="(999)9999-9999"
												autoComplete="off"
												type="tel"
												className="ant-input ant-input-status-success css-dev-only-do-not-override-swt6e8 input"
												onChange={handlePhone1Change}
											>
											</InputMask>
										</Form.Item>

										<p className="input-text">Celular 2:</p>
										<Form.Item
											name="phone2"
										>
											<div>
												<Input
													type="tel"
													className="input"
													placeholder="0000-0000"
													value={cc_format(phone2)}
													onChange={handlePhone2Change}
													maxLength={9}
												/>
											</div>
										</Form.Item>

										<p className="input-text">Intereses:</p>
										<span className="inputInfo">Puede seleccionar varios</span>
										<Form.Item
											name="interest"
											rules={[
												{
													validator: validateInterestSelection,
												},
											]}
										>	
											<Select
												className="input select_style"
												placeholder="Seleccionar intereses"
												mode="multiple"
												options={[
													{
														value: 0,
														label: "Restaurantes y Bares",
													},
													{
														value: 1,
														label: "Deportes",
													},
													{
														value: 2,
														label: "El Balsamar",
													},
													{
														value: 3,
														label: "Cultura",
													},
													{
														value: 4,
														label: "Eventos Sociales",
													},
												]}
											/>
										</Form.Item>

										<div className="flexbox-start">
											<Form.Item
												name="check"
												rules={[
													{
														validator: (_, value) =>
															value
																? Promise.resolve()
																: Promise.reject(
																		new Error(
																			"Debe aceptar los términos y condiciones para continuar"
																		)
																	),
													},
												]}
											>
												<Checkbox
													onChange={handleCheckboxChange}
													className="form-checkbox"
												/>
											</Form.Item>
											<p
												className="checkbox-text"
												style={{ marginLeft: "10px" }}
											>
												Acepto los{" "}
												<span className="green-text">
													Términos y condiciones
												</span>
											</p>
										</div>

										<Button htmlType="submit" className="green-btn">
											GUARDAR
										</Button>
										<br />
											{error === true ? (
												showError(error, "Error, por favor intente de nuevo.")
											) : (
												<></>
											)}
										</div>
									</Form>
								</div>
							</Content>

							<Footer className="footer flexbox-center">
								<SimpleFooter />
							</Footer>
						</Layout>
					</div>
				</div>
			</div>
		</>
	);
};

export default MemberFormPage;
