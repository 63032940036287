import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Card, Drawer, Form, Layout, Input } from "antd";
import { Header, Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { CDIBanner } from "../../components/CDIBanner";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { SimpleFooter } from "../../components/SimpleFooter";
import { submitAccessCode } from "../../../store/helpers/accessCodeHelper";
import { showError } from "../../../store/helpers/generalHelper";

const UserCodeAccessPage = () => {
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const accessFormRef = useRef();
	const navigate = useNavigate();

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const accessMemberForm = async (e) => {
		const clicode = e.code.toUpperCase();
		const response = await submitAccessCode(clicode);
		if (response.status === "success") {
			const encodedCode = encodeURIComponent(clicode);
			navigate(`/formulario/${encodedCode}`, { replace: false });
		} else {
			setError(true);
			setErrorMessage(response.message);
		}
	};

	return (
		<>
			<div className="page-wrapper">
				<div className="flex-container">
					<div className="image-section"></div>
					<div className="content-section">
						<Layout className="layout">
							<Header className="header"></Header>
							<Content  className="content">
								<div className="content-wrapper">
									<p className="input-text">Bienvenido:</p>
									<h1 className="green-title-text">
										FORMULARIO DE ACTUALIZACIÓN DE DATOS
									</h1>
									<p className="description-text">
										Este es un formulario requerido por CDI para qué todos los miembros puedan 
										actualizar sus datos de contacto y de esta forma poder proporcionarle un mejor 
										servicio y mantenerlos correctamente comunicados de todo el acontecer deportivo 
										y social.{" "}
									</p>

									<Form
										ref={accessFormRef}
										name="access_member_form"
										onFinish={accessMemberForm}
									>
										<div className="member-code-container">
											<p className="input-text">Ingresa tu código de miembro:</p>
											<Form.Item name="code" {...config}>
												<Input className="input uppercase" placeholder="X-000" />
											</Form.Item>
											<span className="inputInfo">Incluir guión: por ejemplo A-001</span>
											<Button htmlType="submit" className="green-btn">
												Ingresar
											</Button>
											<br />
											{error === true ? (
												showError(error, errorMessage)
											) : (
												<></>
											)}
										</div>
									</Form>
								</div>
							</Content>

							<Footer className="footer flexbox-center">
								<SimpleFooter />
							</Footer>
						</Layout>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserCodeAccessPage;
