import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./topia/components/scrollToTop";
import UserCodeAccessPage from "./topia/pages/userCodeAccess/UserCodeAccessPage";
import MemberFormPage from "./topia/pages/memberForm/MemberFormPage";
import SuccessFormPage from "./topia/pages/successForm/SuccessFormPage";

function App() {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route path="/*" element={<UserCodeAccessPage />} />
				<Route path="/" element={<UserCodeAccessPage />} />
				<Route path="/formulario/:id" element={<MemberFormPage />} />
				<Route path="/descargar" element={<SuccessFormPage />} />
			</Routes>
		</>
	);
}

export default App;
