import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const SimpleFooter = () => {
	const navigate = useNavigate();
	return (
		<div className="footer-content">
			{/*
			<div className="flexbox-space-between">
				<Button type="link">
					<span className="footer-header-text">Condiciones de uso</span>
				</Button>
				<span className="footer-header-text">|</span>
				<Button type="link">
					<span className="footer-header-text">Política de privacidad</span>
				</Button>
			</div>
			 */}
			 <br/><br/>
			<span className="footer-copyright">2023 Topia App</span>
		</div>
	);
};
