export const showError = (error = null, message) => {
	if (error) {
		return (
			<>
				<p className="input-text-red">{message}</p>
			</>
		);
	} else {
		return null;
	}
};
