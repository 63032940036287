export const submitAccessCode = async (code) => {
	const url = "https://domo.topiaapp.com/api/cdi-landing-busqueda"; //Esperar confirmación apis finales
	const requestBody = JSON.stringify({
		origin_fam: code,
	});

	const requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: requestBody,
	};

	const res = await fetch(url, requestOptions);
	const data = await res.json();

	return data;
};
