export const submitFormValues = async (formValues) => {
	const url = "https://domo.topiaapp.com/api/cdi-landing-registro"; //Esperar confirmación apis finales
	const requestBody = JSON.stringify(formValues);

	const requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: requestBody,
	};

	const res = await fetch(url, requestOptions);
	const data = await res.json();

	if (data.status === "success") {
		return true;
	} else {
		return false;
	}
};

export const getFormValues = async (accessCode) => {
	const url = "https://domo.topiaapp.com/api/cdi-landing-busqueda"; //Esperar confirmación apis finales
	const requestBody = JSON.stringify({
		origin_fam: accessCode,
	});

	const requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: requestBody,
	};

	const res = await fetch(url, requestOptions);
	const data = await res.json();

	if (data.status === "success") {
		return data.info;
	} else {
		return null;
	}
};

export function clearNumber(value = "") {
	return value.replace(/\D+/g, "");
}
