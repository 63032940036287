import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button, Card, Drawer, Form, Layout, Input } from "antd";
import { SimpleFooter } from "../../components/SimpleFooter";
import { Header, Content, Footer } from "antd/lib/layout/layout";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const SuccessFormPage = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="page-wrapper">
				<div className="flex-container">
					<div className="image-section"></div>
					<div className="content-section">
						<Layout className="layout">
							<Header className="header">
								<p className="header-text">
									Formulario de actualización de datos
								</p>
							</Header>
							<Content className="content">
								<div className="content-wrapper">
									<div className="center">
										<img src="/assets/thumbs_up.svg" />
										<br />
										<p className="success-title">
											¡Información guardada con éxito!
										</p>
									</div>
									{/*
									<div className="download-container center">
										<div className="green-border-container">
											<p className="green-subtitle">descargar app</p>
										</div>
										<p className="subtitle-text">
											Ahora procede a descargar la app de Topia para comenzar a
											disfrutar de nuestros servicios:
										</p>

										<Button
											className="iphone-btn"
											onClick={() =>
												(window.location.href =
													"https://apps.apple.com/us/app/topia/id1561189564")
											}
										>
											<div className="flexbox-center">
												<img src="/assets/apple_logo.svg" />
												<div style={{ marginLeft: "10px" }}>
													<span
														className="btn-subtitle"
														style={{ display: "block" }}
													>
														Consíguelo en el
													</span>
													<span className="btn-title">App Store</span>
												</div>
											</div>
										</Button>
										<br />
										<Button
											className="android-btn"
											onClick={() =>
												(window.location.href =
													"https://play.google.com/store/apps/details?id=com.topia.app")
											}
										>
											<div className="flexbox-center">
												<img src="/assets/google_logo.svg" />
												<div style={{ marginLeft: "10px" }}>
													<span
														className="btn-subtitle"
														style={{ display: "block" }}
													>
														Disponible en
													</span>
													<span className="btn-title">Google Play</span>
												</div>
											</div>
										</Button>
									</div>
									*/}
								</div>
							</Content>

							<Footer className="footer flexbox-center">
								<SimpleFooter />
							</Footer>
						</Layout>
					</div>
				</div>
			</div>
		</>
	);
};

export default SuccessFormPage;
